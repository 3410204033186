var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"data-list"}},[_c('vx-card',{ref:"filterCard",staticClass:"user-list-filters mb-8",attrs:{"title":"Adding new company","collapse-action":"","refresh-content-action":""},on:{"refresh":_vm.resetColFilters}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col md:w-1/2 sm:w-1/2 w-full mb-2"},[_c('div',{staticClass:"vx-row"},[_c('div',{staticClass:"vx-col w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("COMPANY NAME ")]),_c('validation-provider',{attrs:{"name":"company_name","rules":"required|min:2"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-terminal","icon-no-border":"","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.company_name),callback:function ($$v) {_vm.company_name=$$v},expression:"company_name"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("COMPANY CODE ")]),_c('validation-provider',{attrs:{"name":"company_code","rules":{
        required: true,
        min:1
      }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var errors = ref.errors;
return [_c('vs-input',{staticClass:"w-full",attrs:{"icon-pack":"feather","icon":"icon-hash","icon-no-border":"","danger":!!errors[0],"danger-text":errors[0]},model:{value:(_vm.company_code),callback:function ($$v) {_vm.company_code=$$v},expression:"company_code"}})]}}],null,true)})],1),_c('div',{staticClass:"vx-col w-full mb-2"},[_c('label',{staticClass:"text-sm opacity-75"},[_c('span',{staticClass:"text-primary"},[_vm._v("*")]),_vm._v("Upload Image ")]),_c('vs-upload',{ref:"getFile",attrs:{"text":"Upload Image","limit":"1"},on:{"change":_vm.getFile}})],1)])])]),_c('vs-button',{staticClass:"mb-2",attrs:{"disabled":invalid,"type":"filled"},on:{"click":function($event){return _vm.addCompany()}}},[_vm._v("SUBMIT")])],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }