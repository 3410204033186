<!-- =========================================================================================
  Author: Nada Azzam
========================================================================================== -->

<template>
  <div id="data-list">
    <vx-card
      ref="filterCard"
      title="Adding new company"
      class="user-list-filters mb-8"
      collapse-action
      refresh-content-action
      @refresh="resetColFilters"
    >
      <validation-observer ref="observer" v-slot="{ invalid }">
        <form>
          <div class="vx-row">
            <div class="vx-col md:w-1/2 sm:w-1/2 w-full mb-2">
              <div class="vx-row">
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY NAME
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="company_name"
                    rules="required|min:2"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-terminal"
                      icon-no-border
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                      v-model="company_name"
                    />
                  </validation-provider>
                </div>

                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>COMPANY CODE
                  </label>
                  <validation-provider
                    v-slot="{ errors }"
                    name="company_code"
                    :rules="{
          required: true,
          min:1
        }"
                  >
                    <vs-input
                      class="w-full"
                      icon-pack="feather"
                      icon="icon-hash"
                      icon-no-border
                      v-model="company_code"
                      :danger="!!errors[0]"
                      :danger-text="errors[0]"
                    />
                  </validation-provider>
                </div>
                <div class="vx-col w-full mb-2">
                  <label class="text-sm opacity-75">
                    <span class="text-primary">*</span>Upload Image
                  </label>
                  <vs-upload ref="getFile" text="Upload Image" @change="getFile" limit="1" />
                </div>
              </div>
            </div>
          </div>

          <vs-button :disabled="invalid" type="filled" @click="addCompany()" class="mb-2">SUBMIT</vs-button>
        </form>
      </validation-observer>
    </vx-card>
  </div>
</template>

<script>
import axios from "@/axios.js";
import endpoints from "@/endpoints";
import { required, min } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
// betterEager
extend("required", {
  ...required,
  message: "field can not be empty",
});

extend("min", {
  ...min,
  message: "field may not be less than {length} characters",
});

// Store Module

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      company_name: null,
      company_code: null,
      company_image: null,
      fileRequired:false

      // Cell Renderer Components
    };
  },
  watch: {
    company_name(obj) {
      if (obj.length > 2) {
        var res = obj.substring(0, 2);
        var res1 = res.toUpperCase();
        this.company_code = res1 + "_";
      }
    },
  },
  computed: {
    itemsData() {
      return this.$store.state.hrReportManagement.items;
    },
  },
  methods: {
    getFile() {

      this.company_image = this.$refs.getFile.filesx[0];
    },
    resetColFilters(card){
      card.removeRefreshAnimation(100);
      this.category_name = null;
    },
    addCompany() {
      if(!this.company_image){
        this.$vs.notify({ color: 'danger', title: 'Image is Required' })
      }else{
          this.$vs.loading();
      const formData = new FormData();
      formData.append("name", this.company_name);
      formData.append("code", this.company_code);
      formData.append("image", this.company_image);

      axios
        .post(endpoints.COMPANIES_ENDPOINT, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.$vs.loading.close();
          if (response.status !== 200) {
            this.$vs.notify({
              title: "Error",
              text: "Company is not added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "danger",
            });
          } else {
            this.$vs.notify({
              title: "Success",
              text: "Company is added",
              iconPack: "feather",
              icon: "icon-alert-circle",
              color: "success",
            });
      this.$router.push("../GetAll/Company");

          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error",
            text: error.response.data.errors[0],
            iconPack: "feather",
            icon: "icon-alert-circle",
            color: "danger",
          });
        });
      }
    
    },
  },
  goToCompanyList() {
     this.$router.push("/GetAll/Company");
    },
  mounted() {},
  created() {},
};
</script>

