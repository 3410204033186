const BASE_URL = process.env.VUE_APP_BASE_URI;
const ADMIN = "admin/";
const API_URL = BASE_URL + "/api/" + ADMIN;
const SIGN_IN = "login";
const SIGN_UP = "register";
const COMPANIES = "companies";
const NEWS = "news";
const ROLES="roles"
const PERMISSIONS="permissions"
const DEPARTMENT ="departments"
const JOB_TITLES ="job_titles"
const USER="users"
const CITY="city"
const TIME = "times"
const GENERATE_CODE="generate_code"

export default {
  BASE_URL: BASE_URL,
  API_URL: API_URL,
  ADMIN_ENDPOINT: API_URL + ADMIN,
  SIGN_IN_ENDPOINT: API_URL + SIGN_IN,
  SIGN_UP_ENDPOINT: API_URL + SIGN_UP,
  COMPANIES_ENDPOINT: API_URL + COMPANIES,
  NEWS_ENDPOINT: API_URL + NEWS,
  ROLES_ENDPOINT: API_URL + ROLES,
  PERMISSIONS_ENDPOINT: API_URL + PERMISSIONS,
  DEPARTMENT_ENDPOINT: API_URL + DEPARTMENT,
  JOB_TITLES_ENDPOINT: API_URL + JOB_TITLES,
  USER_ENDPOINT: API_URL + USER,
  CITY_ENDPOINT: API_URL + CITY,
  TIME_ENDPOINT: API_URL + TIME,
  GENERATE_CODE_ENDPOINT: API_URL + GENERATE_CODE,
};
